/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import { SEO } from '../../components/seo';
import { useTranslation } from 'react-i18next';

const IndexPage = () =>
{
  const { t } = useTranslation();

  return (
    <>
      <SEO
        title={t('100+ GPT Prompt Inspirations')}
        noindex={true}
      ></SEO>
    <div className="w-screen h-screen">
      <iframe src="https://airtable.com/embed/shrgqn0kmxEc8A7zR?backgroundColor=purple&layout=card&viewControls=on" frameborder="0" onmousewheel="" width="100%" height="100%"></iframe>
    </div>
    </>
  );
};

export default IndexPage;
